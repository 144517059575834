<template>
  <v-card>
    <v-card-title>
      QNA 
    </v-card-title>
    <v-card-subtitle>
        QNA
    </v-card-subtitle>

    <v-card-text>
        <v-expansion-panels popout>

      <v-expansion-panel>
        <v-expansion-panel-header>1. 어떤 정보도 보이지 않습니다. 어떻게 시작합니까?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            [대시보드]에서 "충전 스테이션 공유받기" 를 클릭하여 공유 코드를 입력 하여야 합니다. 
          </p>
          <p>
            공유코드는 메일을 통해 전달되므로, [1:1 문의] 에서 메일 주소와 함께 공유 코드 요청을 하시면 됩니다. <br/>
            만약 [대시보드]에서 "충전 스테이션 공유받기"가 보이지 않는다면, [대시보드 설정] 에서 "충전 스테이션 추가 패널 보기" 를 활성화 하여야 합니다.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>2. 과금 정책을 변경하고 싶습니다. 어떻게 처리합니까?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            [대시보드]의 충전소 패널 우측 상단을 클릭하여, 메뉴를 펼친 후, "과금 정책 관리" 를 클릭하여 새로운 과금 정책을 입력 한 후 저장 합니다. 
          </p>
          <p>
            [대시보드]의 충전소 패널 중앙의 충전기 아이콘을 클릭하여, 메뉴를 펼친 후, "과금 정책 설정" 를 클릭하여 저장된 과금 정책 을 추가 합니다. <br/>
            관련 질의 사항은 [1:1 문의] 를 이용하여 주십시오.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>3. OCPP 란 무엇입니까?</v-expansion-panel-header>
        <v-expansion-panel-content>

          <p>
            OCPP (Open Charge Point Protocol) 란 충전 설비(Charge Point / Charging Station) 와 중앙 관리 시스템 (Central System / Charging Station Management System) 
            간의 표준 통신 규약입니다. OCPP 를 통해 다양한 제조사의 충전 설비들과 통신하여, 충전 및 충전기 관리 등 충전 사업자가 서비스를 운영하는 데 필요한 데이터를 효과적으로 관리할 수 있습니다.
          </p>
          <p>
            OCPP 는 JSON 메세지를 주고 받는 (Standard) Websocket 통신을 권장합니다. 
          </p>
          <p>
            OCPP 2.0.1 Specification
            <ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0.1_part0_introduction.pdf" target="_new"> OCPP-2.0.1_part0_introduction.pdf</a></li>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0.1_part1_architecture_topology.pdf" target="_new"> OCPP-2.0.1_part1_architecture_topology.pdf</a></li>
              <ul>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0_part1_errata.pdf" target="_new"> OCPP-2.0_part1_errata.pdf</a></li>
              </ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0.1_part2_specification.pdf" target="_new"> OCPP-2.0.1_part2_specification.pdf</a></li>
              <ul>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0_part2_errata.pdf" target="_new"> OCPP-2.0_part2_errata.pdf</a></li>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0.1_part2_appendices.pdf" target="_new"> OCPP-2.0.1_part2_appendices.pdf</a></li>
              </ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0.1_part3_JSON_schemas.zip" target="_new">OCPP-2.0.1_part3_JSON_schemas.zip </a></li>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0.1_part4_ocpp-j-specification.pdf" target="_new"> OCPP-2.0.1_part4_ocpp-j-specification.pdf</a></li>
              <ul>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/OCPP-2.0_part4_errata.pdf" target="_new"> OCPP-2.0_part4_errata.pdf</a></li>
              </ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP-2.0.1-Specification/Changelog OCPP 2.0 - 2.0.1.pdf" target="_new"> Changelog OCPP 2.0 - 2.0.1.pdf</a></li>
            </ul>
          </p>
          <p>
            OCPP 1.6 Specification
            <ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/ocpp-1.6 edition 2.pdf" target="_new"> ocpp-1.6 edition 2.pdf</a></li>
              <ul>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/ocpp-1.6-errata-sheet.pdf" target="_new"> ocpp-1.6-errata-sheet.pdf</a></li>
              </ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/ocpp-j-1.6-specification.pdf" target="_new"> ocpp-j-1.6-specification.pdf</a></li>
              <ul>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/ocpp-j-1.6-errata-sheet.pdf" target="_new"> ocpp-j-1.6-errata-sheet.pdf</a></li>
              </ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/ocpp-s-1.6-specification.pdf" target="_new"> ocpp-s-1.6-specification.pdf</a></li>
              <ul>
                <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/ocpp-s-1.6-errata-sheet.pdf" target="_new"> ocpp-s-1.6-errata-sheet.pdf</a></li>
              </ul>
              <li><a href="/docs/EVInfra4OCPP_part3_ocpp_v1.0/ocpp/OCPP_1.6_documentation_2019_12/schemas/json/OCPP-1.6-JSON-Schemas.zip" target="_new"> OCPP-1.6-JSON-Schemas.zip</a></li>
            </ul>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>4. EVInfra for OCPP 란 무엇입니까?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            "EV Infra for OCPP" 는 OCPP 기반의 충전 서비스 플렛폼 입니다. 다수의 확장 가능한 애플리케이션들이 MAS(Micro Service Architeture) 로 구성되어 있습니다.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>5. EVInfra for OCPP 는 OCPP Message 를 어떻게 처리합니까?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            "EV Infra for OCPP" 는 OCPP Standard WebSocket 과 함께 STOMP Websocket 을 지원하고 있습니다.<br/> 
            따라서, OCPP 메세지를 STOMP 를 통해 brodcasting 하여 확장 및 변경 가능한 메세지 핸들 프로세서를 갖추고 있습니다.             
          </p>
          <p>
            아레는 메세지 처리 과정을 도식화한 이지미지입니다.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>6. OCPP 기반의 충전 설비 제조 사업자 입니다. EVInfra for OCPP 를 OCPP Server 로 사용 할 수 있습니까?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            "EV Infra for OCPP" 는 Websocket 접속 주소를 기준으로 충전소와 충전기를 구분하기 때문에, 
            제조사가 고유의 접속 주소를 할당 받으면, <br/> 그대로 사용 하는데 아무 문제가 없으며, 
            Softberry 가 가진 방대한 회원정보를 이용한 충전 서비스를 시작하실 수 있습니다. <br/>
            기술지원 이나 보다 자세한 문의는 [1:1 문의] 를 이용하여 주십시오.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>7. EVInfra for OCPP 를 이용하여 나만의 로직 또는 화면을 개발 할 수 있습니까?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            "EV Infra for OCPP" 가 제공하는 STOMP 에 접속하는 코드를 추가하여, 고유한 비즈니스 로직이나 화면을 구성하는 애플리케이션을 개발 할 수 있습니다.<br/>
            기술지원 이나 보다 자세한 문의는 [1:1 문의] 를 이용하여 주십시오. 
          </p>
          <p>
              <a href="/docs/index.html" target="_new">Developer Site</a>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    </v-card-text>

    <v-dialog v-model="progressDialog" width="100%" persistent>
      <v-progress-circular
        indeterminate
        color="deep-purple accent-4"
      ></v-progress-circular>
    </v-dialog>

  </v-card>

</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import Validator from '../../app.validator.js'

export default {

  name: "Qna",
  data : ()=>({

    progressDialog: false,

    element : {},
    rules : {
      body: [
        Validator.required('문의 내용이 필요합니다.')
      ],
      reply: [
        Validator.required('연락받으실 메일 주소 또는 전화번호가 필요합니다.')
      ],
    },

  }),
  methods : {

    send(){
      if (!this.$refs.form.validate()) return;

      this.progressDialog = true;
      this.$confirm({
          message: '문의 내용을 전달 하시겠습니까?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              OCPP_API.inquiry.create(this.element).then(()=>{

                this.progressDialog = false;
                this.$confirm({
                    message: '문의 내용을 접수 하였습니다. 곧 '+this.element.reply+'로 답변드리겠습니다.',
                    button: {
                      yes: '확인',
                    },
                    callback: () => {

                      this.element = {};
                      this.$refs.form.reset();
                    }
                });
              }).catch(()=>{
                this.progressDialog = false;

                this.$confirm({
                    message: '시스템 점검 중 입니다. 지금은 문의 내용을 접수 할 수 없습니다. ',
                    button: {
                      yes: '확인',
                    },
                    callback: () => {
                      this.element = {};
                      this.$refs.form.reset();
                    }
                });
              })
            }
          }
        }
      );
    }
  },
  mounted() {
    this.element = {};
  }
}
</script>

<style></style>